<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.canchas.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.canchas.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.canchas.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon dark left>
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  :items="filters.data.items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.sucursales.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  v-model="filters.selected.items"
                  outlined
                  rounded
                  dense
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  v-model="filters.selected.query"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <canchas-add />
    <canchas-edit />
    <canchas-detail />
    <canchas-delete />
  </v-container>
</template>

<script>
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

    export default {
      name: "canchas",
      mixins: [customFilter],
      components: {
        CanchasAdd: () => import('./CanchasAdd'),
        CanchasEdit: () => import('./CanchasEdit'),
        CanchasDetail: () => import('./CanchasDetail'),
        CanchasDelete: () => import('./CanchasDelete')
      },
      data () {
        return {
          headers: [
            {
              text: this.$t('app.headers.id'),
              value: 'id',
            },
            {
              text: this.$t('app.headers.name'),
              value: 'nombre',
            },
            {
              text: this.$t('app.headers.description'),
              value: 'descripcion',
            },
            {
              text: this.$t('app.sucursales.single'),
              value: 'sucursal.nombre',
            },
            {
              text: '',
              value: 'actions',
              sortable: false
            }
          ],
          items: [],
          filters: {
            data: {
              items: []
            },
            selected: {
              items: [],
              query: ''
            }
          }
        }
      },
      computed: {
        filteredItems () {
          if (this.filters.selected.items.length > 0) {
            return this.items.filter(item => this.filters.selected.items.includes(item.sucursal_id))
          } else {
            return this.items
          }
        },
        queryClean () {
          return latinize(this.filters.selected.query)
        }
      },
      mounted() {
        this.init()

        EventBus.$on('reload-items', () => {
          this.init()
        })
      },
      methods: {
        async init () {
          this.toggleLoader()

          this.filters.data.items = await this.getBranches()
          this.items = await this.getCanchas()

          this.toggleLoader()
        },
        openItemAdd () {
          EventBus.$emit('canchas-add')
        },
        openItemEdit (item) {
          EventBus.$emit('canchas-edit', item)
        },
        openItemDetail (item) {
          EventBus.$emit('canchas-detail', item)
        },
        openItemDelete (item) {
          EventBus.$emit('canchas-delete', item)
        },
      }
    }
</script>

<style scoped>

</style>
